<template>
  <p v-if="as === 'p'" :class="clazz">
    <slot />
  </p>
  <span v-else :class="clazz">
    <slot />
  </span>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    variant:
      | 'heading-6'
      | 'subtitle-1'
      | 'subtitle-2'
      | 'body-1'
      | 'body-2'
      | 'caption'
    as?: 'p' | 'span'
    emphasis?: 'high' | 'medium'
  }>(),
  {
    as: 'p',
    emphasis: 'high',
  }
)

const clazz = computed(() => {
  const classList = []
  if (props.emphasis === 'high') {
    classList.push('text-emphasis-high')
  } else if (props.emphasis === 'medium') {
    classList.push('text-emphasis-medium')
  }
  switch (props.variant) {
    // subtitleはマテリアルデザインのままだとbodyと違いがほとんどないので、kickflowではboldにしている。
    case 'heading-6':
      classList.push('text-h6') // 20px semi-bold
      break
    case 'subtitle-1':
      classList.push('text-body-1', 'font-weight-bold') // 16px bold
      break
    case 'subtitle-2':
      classList.push('text-body-2', 'font-weight-bold') // 14px bold
      break
    case 'body-1':
      classList.push('text-body-1') // 16px regular
      break
    case 'body-2':
      classList.push('text-body-2') // 14px regular
      break
    case 'caption':
      classList.push('text-caption') // 12px regular
      break
    default:
      // do nothing
      break
  }
  return classList.join(' ')
})
</script>

<style scoped></style>
